import React from 'react';
import styled, { css } from 'styled-components';
import { MediaBreakpoints } from '../../common/MediaBreakpoints';
import { TextTheme } from '../../common/StyleTheme';



const OuterDivContainer = styled.div`
    display: flex;
    flex-direction: ${({ isReversed }) => isReversed && "row-reverse"};
    align-items: center;
    margin-bottom: 1em;
    flex-wrap: wrap-reverse;

    @media screen and (max-width: ${MediaBreakpoints.mobile}px){
        justify-content: center;
    }
`;

const SubjectContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 100%;
    align-items: center; 

    @media screen and (max-width: ${MediaBreakpoints.mobile}px){
       max-width: 95%;
    }
`;

const SubjectImage = styled.img`
    ${({ imgSize }) => imgSize && css` 
        width: ${imgSize.large};
        height: ${imgSize.large};
    `}
`;

const SubjectTitle = styled.h2`
    color: black;
    font-size: 20px;
    font-weight: 700;
    margin: 1em 0em;

    @media screen and (max-width: ${MediaBreakpoints.mobile}px){
      margin-top:1.5em;
    } 
`;

const SubjectDescription = styled.p`
    color: ${TextTheme.PrimaryColor}; 
    font-size:  ${TextTheme.Size};
    line-height: ${TextTheme.LineHeight};
    text-align:  ${TextTheme.Align};
    max-width: 50%;

    @media screen and (max-width: ${MediaBreakpoints.mobile}px){
       max-width: 95%;
       margin-bottom: 0em;
    }
`;

export function BusinessArea(props) {
    const { imgSize, title, description, imgUrl, isReversed } = props;

    return (
        <OuterDivContainer isReversed={isReversed}>
            <SubjectContainer>
                <SubjectTitle>{title}</SubjectTitle>
                <SubjectDescription>{description}</SubjectDescription>
            </SubjectContainer>
            <SubjectImage width={imgSize} height={imgSize} imgSize={imgSize} src={imgUrl} alt="" />
        </OuterDivContainer>
    );
}

export default BusinessArea;