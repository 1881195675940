import React from 'react';
import styled from 'styled-components';
import BusinessArea from '../components/BusinessArea';
import TitleSection from '../components/TitleSection';
import Marginer from '../../common/Marginer';
import Line from '../components/Line';
import AIImg from '../../images/Logo-AIMedtech-1.png';
import MedtechImg from '../../images/Logo-AIMedtech-2.png';
import HealthImg from '../../images/Logo-AIMedtech-1.png';

const BusinessSectionContainer = styled.div`
    width: 100%;
    min-height: 90px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
`;

export function BusinessSection() {
    return (
        <BusinessSectionContainer name="BusinessSection">
            <TitleSection>ARTIFICIAL INTELLIGENCE &amp; MEDICAL TECHNOLOGY</TitleSection>
            <Line />
            {/* <Marginer direction="vertical" margin="2em" /> */}
            <BusinessArea title="Artificial Intelligence" description="Artificial intelligence (AI) is an area in computer science that creates innovative solutions to perform tasks that typically require human
            intelligence. Advances in AI, such as deep learning, have created a paradigm shift in several different industries. 
            This new AI paradigm has led to improvements in processes and approaches that, in collaboration with humans, surpass traditional methods." />

            {/* <Marginer direction="vertical" margin="3em" /> */}
            <BusinessArea title="Medical Technology"  description="Medical technologies are products, services or solutions to diagnose and treat diseases. Early, rapid, and accurate diagnostic information on health problems facilitates intervention and enables healthcare professionals to make clinical decisions that optimize patient outcomes. Innovative medical devices can complement standard methods and thus improve both healthcare efficiency and patient health." />

            {/* <Marginer direction="vertical" margin="3em" /> */}
            <BusinessArea  title="Diagnostics" description="AI Medtech believes that future healthcare will be a successful combination of technologies collaborating with human intervention and monitoring. The possibilities that AI adds to medical technology are many. Above all, to predict diseases with higher precision than traditional methods. Through this type of preventive measure,
             human lives can be saved, and resources in healthcare freed up." />
        </BusinessSectionContainer>
    );
};

export default BusinessSection;