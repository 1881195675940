import React from 'react';
import styled from 'styled-components';
import { ButtonTheme } from '../../common/StyleTheme';

const ButtonWrapper = styled.button`
    padding: ${({ small }) => (small ? "5px 8px" : "7px 15px")};
    border-radius: 5px;
    background-color: ${ButtonTheme.PrimaryColor};
    color: #fff;
    font-weight: 500;
    font-size: ${({ small }) => (small ? "13px" : "17px")};
    outline: none;
    border: 2px solid transparent;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    &:hover{
        background-color: transparent;
        border: 2px solid ${ButtonTheme.PrimaryColor};
    }
`;

export function ButtonWebsite(props) {
    return <ButtonWrapper {...props}> {props.children}</ButtonWrapper>
}

export default ButtonWebsite;