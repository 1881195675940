import React from 'react';
import styled from 'styled-components';
import ButtonWebsite from '../components/ButtonWebsite';
import Logo from '../../common/Logo';
import Marginer from '../../common/Marginer';
import { BackgroundTheme } from '../../common/StyleTheme';
import { FaTwitter, FaLinkedin } from "react-icons/fa";

const FooterContainer = styled.div`
    width: 100%;
    height: 450px;
    display: flex;
    flex-direction: column;
    background-color: ${BackgroundTheme.PrimaryColor};
    align-items: center;
    position: relative;
`;

const Title = styled.h1`
    font-size: 18px;
    font-weight: 700;
    color: #fff;
    margin: 0;
    line-height: 1.3em;
`;

const FooterText = styled.p`
    color: #fff;
`;

const LinksContainer = styled.span`
  width: 80%;
  display: flex;
  border-top: 1px solid #cdcdcd;
  padding-top: 12px;
  padding-right: 10px;
  padding-left: 10px;
  color: #fff;
  justify-content: space-between;
  padding: 1.125em;
  margin: 1.125em;
`;

const PrivacyContainer = styled.div`
    display:flex;
`;

const SocialIconsContainer = styled.div`
    display:flex;
`;

const SocialIcon = styled.div`
    color: #fff;
    font-size: 20px;
    transition: all 200ms ease-in-out;
    cursor: pointer;

    &:not(:last-of-type){
        margin-right: 30px;

        @media screen and (max-width: 480px){
            margin-right: 5px;
        }
    }

    &:hover{
        color:#adadad
    };

`;

const Link = styled.a`
    color: #fff;
    transition: all 200ms ease-in-out;
    cursor: pointer;
    font-size: 12px;

    &:not(:last-of-type){
        margin-right: 11px;
    }

    &:hover{
        color:#adadad;       
    }
    
    
`;

const RightsReserved = styled.div`
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: #fff;
    font-size: 12px;
`;

export function Footer(props) {
    return (
        <FooterContainer>
            <Marginer direction="vertical" margin="2em" />
            <Logo logoImage={"Logo1"} logoStyle={{ align: "center", width: "3.5em", height: "3.5em", }} />
            <Marginer direction="vertical" margin="2em" />
            <Title>AI Medical Technology</Title>
            <Title>Image Analysis in Healthcare</Title>
            <Marginer direction="vertical" margin="2em" />
            <FooterText>Want to know more?</FooterText>
            <Marginer direction="vertical" margin="1.5em" />
            <ButtonWebsite>Contact Us</ButtonWebsite>
            <Marginer direction="vertical" margin="6em" />
            <LinksContainer>
                <PrivacyContainer>
                    <Link href={"/privacypolicy"}>Privacy Policy</Link>
                    <Link href={""}>Terms of Service</Link>
                    <Link href={""}>Contact Us</Link>
                </PrivacyContainer>
                <SocialIconsContainer>
                    <SocialIcon>
                        <FaTwitter />
                    </SocialIcon>
                    <SocialIcon>
                        <FaLinkedin />
                    </SocialIcon>
                </SocialIconsContainer>
            </LinksContainer>
            <RightsReserved>Copyright &copy; 2020 by AI Medical Technology. All rights reserved.</RightsReserved>
        </FooterContainer>
    );
}

export default Footer;