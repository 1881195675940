import React from 'react';
import { PageContainer} from '../../common/PageContainer';
import Header from '../containers/Header';
import BusinessSection from '../containers/BusinessSection';
// import SolutionsSection from '../containers/SolutionsSection';
import Marginer from '../../common/Marginer';
import Footer from '../containers/Footer';

export function Website(props) {
    return (
        <PageContainer>
            <Header />
            <BusinessSection />
            {/* <SolutionsSection/> */}
            <Marginer direction="vertical" margin="4em" />
            <Footer/>
        </PageContainer>
    );
}

export default Website;
