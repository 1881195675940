import styled from 'styled-components';

export const TitleSection = styled.h1`
    font-size: 25px;
    font-weight: 600;
    margin-top: 35px;
    color: black;
    text-align: center;
`;

export default TitleSection;