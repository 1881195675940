export const headerVariants = {
    in: {
        opacity: 1,
        // x: 0,
    },
    out: {
        opacity: 0,
        // x: "-100vw",
    }
}

export const headerLogoTransition = {
    delay: 0.25,
    duration: 2,
}

export const headerTitleTransition = {
    delay: 1.5,
    duration: 2,
}

export const headerButtonTransition = {
    delay: 2.5,
    duration: 1,
}

export const navbarVariants = {
    in: {
        opacity: 1,
        y: 0,
    },
    out: {
        opacity: 0,
        y: "-5vw",
    }
}

export const navbarTransition = {
    delay: 1.5,
    duration: 2.2,
}