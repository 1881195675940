import React from 'react';
import Web_HomePage from './pages/Web_HomePage';
import Web_PrivacyPolicy from './pages/Web_PrivacyPolicy';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

function Website() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Web_HomePage} />
        <Route exact path="/privacypolicy" component={Web_PrivacyPolicy} />
      </Switch>
    </Router>
  );
}
 
export default Website;
