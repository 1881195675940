import React from 'react';
import styled from 'styled-components';
import { scroller } from 'react-scroll';
// import { useMediaQuery } from 'react-responsive';
// import { MediaBreakpoints } from '../../common/MediaBreakpoints';
// import BackgroundImg from '../../images/AI_Background-13.jpg'    
// import BackgroundImg from '../../images/AI_Background-15.jpg'
// import BackgroundImg from '../../images/AI_Background-18.jpg'
import BackgroundImg from '../../images/AI_Background-26.jpg'
import Logo from '../../common/Logo';
import Marginer from '../../common/Marginer';
import ButtonWebsite from '../components/ButtonWebsite';
import ButtonDown from '../components/ButtonDown';
import Navbar from '../../common/Navbar';
import { motion } from 'framer-motion';
import {
    headerVariants,
    headerTitleTransition,
    headerLogoTransition,
    headerButtonTransition,
} from './WebsiteAnimation';


//100vh = virtual height. This uses whatever height that the device (e.g mobile or laptop) has when the page loads. 
const HeaderContainer = styled.div`
    width: 100%;
    height: 100vh;
    padding: 0 ;
    margin: 0;
    background-image: url(${BackgroundImg});
    /* background-position: center; */
    background-size: cover;
    background-attachment: fixed;
    position: relative;
`;

const BackgroundFilter = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255, 0.9);
    background-color: rgba(0,0,0, 0.75);
    /* background-color: rgba(0,0,0, 0.6); */
    /* background-color: rgba(45,55,75, 0.6); */
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Title = styled.h1`
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    margin: 0em;
    line-height: 1.3em;
    text-align: center;
    letter-spacing: 0.25em;
`;

const SubTitle = styled.h2`
    font-size: 20px;
    font-weight: 400;
    color: #fff;
    margin: 0em;;
    line-height: 1.3em;
    text-align: center;
    letter-spacing: inherit;
`;

const ButtonDownContainer = styled(motion.div)`
    position: absolute;
    bottom: 30px;
    /* left: 50%; */
    /* transform: translateX(-50%); */
    display: flex;
    justify-content: center;   
`;

const scrollToSection = () => {
    scroller.scrollTo("BusinessSection", { smooth: true, duration: 750, offset: 1, delay: 0, });
}


export function Header(props) {
    // const isTablet = useMediaQuery({maxWidth: MediaBreakpoints.tablet});

    return (
        <HeaderContainer name="Header">
            <BackgroundFilter>

        
                
                <Navbar useTransparent />

                <Marginer  direction="vertical" margin="6em"/>
                <SubTitle>Website Under development - More Info Coming Soon!</SubTitle>

                <Marginer direction="vertical" margin="2em" />


                <motion.div
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={headerVariants}
                    transition={headerLogoTransition}>
                    <Marginer direction="vertical" margin="4em" />
                    <Logo logoImage={"Logo1"} logoStyle={{width: "3.5em", height: "3.5em"}}/>
                </motion.div>

           

                <motion.div
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={headerVariants}
                    transition={headerTitleTransition}>
                    <Marginer direction="vertical" margin="2em" />
                    <Title>AI MEDICAL TECHNOLGY</Title>
                    <Marginer direction="vertical" margin="1em" />
                    <SubTitle>Image Analysis in Healthcare</SubTitle>
                    <Marginer direction="vertical" margin="2em" />
                </motion.div>
             
                <motion.div
                    initial="out"
                    animate="in"
                    exit="out"
                    variants={headerVariants}
                    transition={headerButtonTransition}>
                    <ButtonWebsite onClick={scrollToSection}>More Information</ButtonWebsite>
                </motion.div>

                <ButtonDownContainer whileHover={{ scale: 1.2 }} onClick={scrollToSection}>
                    <ButtonDown />
                </ButtonDownContainer>

            </BackgroundFilter>
        </HeaderContainer >
    );
}

export default Header;