export const BackgroundTheme = {
    PrimaryColor: "#000",
    SecondaryColor: "",
    TertiaryColor: "",
    QuaternaryColor: "",
    GradientPrimaryColor:"#00e3ad",
    GradientSecondaryColor:"#00b997",
};

export const TextTheme = {
    PrimaryColor: "BLACK",
    SecondaryColor: "#7A7A7A",
    TertiaryColor: "#F5F5F5",
    QuaternaryColor: "#F1C40F",
    Size: "16px",
    LineHeight: "1.5em",
    Align: "justify",
};

export const ButtonTheme = {
    PrimaryColor: "#00B997",
    SecondaryColor: "#2F89FC",
    TertiaryColor: "#32C2E3",
    
    PrimaryGradientPrimaryColor:"#00CFA2",
    PrimaryGradientSecondaryColor:"#00D1A4",

    SecondaryGradientPrimaryColor:"#38bc83",
    SecondaryGradientSecondaryColor:"#35bd86",

    TertiaryGradientPrimaryColor:"#2B81FC",
    TertiaryGradientSecondaryColor:"#338AFC",
};

export const LineTheme = {
    PrimaryColor: "#00B997",
    SecondaryColor: "#4A4A4A",
    TertiaryColor: "#A4A4A4",
};

export const LogoTheme = {
    PrimaryColor: "#00e3ad",
    SecondaryColor: "#00B997",
    TertiaryColor: "",
};

export const NavbarTheme = {
    PrimaryColor: "#00B997",
    SecondaryColor: "#00B997",
};

export const HeaderTheme = {
    PrimaryColor: "",
    SecondaryColor: "",
    TertiaryColor: "",
    QuaternaryColor: "",
    Size: "",
    LineHeight: "",
    Align: "",
};

//Dark Green        #00B997
//Light Green       #00D4A2
//Blue              #32C2E3
//Dark Blue         #2F89FC 
//Lighter Blue      #318EFC
//Grey              #40514E
//White             #F5F5F5
//Orange            #F1C40F
//Text Dark Grey    #4A4A4A
//Text light Grey   #A4A4A4

// Green color START CAMERA BUTTON from first prototype left side in gradient 38BC83
// Green color START CAMERA BUTTON from first prototype right side in gradient 35BD86
