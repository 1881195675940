import React from 'react';
import styled from 'styled-components';
import { FaAngleDown } from "react-icons/fa";

import { ButtonTheme } from '../../common/StyleTheme';

const Circle = styled.div`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    /* background-color: rgba(113,113,113, 0.75); */
    background-color: rgba(50,50,50, 0.90);
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid transparent;
    transition: all 250ms ease-in-out;
    cursor: pointer;

    &:hover{
        border: 2px solid ${ButtonTheme.PrimaryColor};
    }
`;

const IconContainer = styled.div`
    margin-top: 5px;
    color: #fff;
    font-size: 28px;
`;

export function ButtonDown() {
    return (
        <Circle>
            <IconContainer>
                <FaAngleDown />
            </IconContainer>
        </Circle>
    );
}

export default ButtonDown;