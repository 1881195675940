import React from 'react';
import ReactDOM from 'react-dom';
import Website from './website/website';


import './css/Reset.css';
import './css/Normalize.css';
import './css/Index.css';
import { BrowserRouter as Router } from 'react-router-dom';

// -----------------------------------------------------//
// StrictMode  
// -----------------------------------------------------//

ReactDOM.render(  
  <React.StrictMode>
    <Router>
      </Router>
    <Website />
  </React.StrictMode>,
  document.getElementById('root')
);


