import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import WebsiteButton from '../website/components/ButtonWebsite';
import Marginer from './Marginer';
import { scroller } from 'react-scroll';
import { NavbarTheme, TextTheme } from "./StyleTheme";
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { MediaBreakpoints } from './MediaBreakpoints';
import { motion } from 'framer-motion';
import {
    navbarVariants,
    navbarTransition,
} from '../website/containers/WebsiteAnimation';


const NavbarContainer = styled(motion.div)`
    width: 100%;
    height: 65px;
    padding: 0 2em;
    display: flex;
    /* flex-direction: row-reverse; */
    align-items: center;
    justify-content: space-between;
    /* justify-content: flex-end; */
    background-color: ${({ useTransparent }) => useTransparent ? "transparent" : NavbarTheme.PrimaryColor};
`;

const LinksContainer = styled.div`
    display:flex;
    flex-direction: row;
    align-items: center;
`;

const LoginText = styled.p`
    color: ${TextTheme.TertiaryColor}; 
    font-size:  ${TextTheme.Size};
    line-height: ${TextTheme.LineHeight};
    text-align:  ${TextTheme.Align};
    margin: 0px;

    @media screen and (max-width: ${MediaBreakpoints.tablet}px){
        font-size: 80%;
        margin: 0 10px;
    }
`;

const Seperator = styled.div`
  min-height: 30px;
  width: 2px;
  background-color: #fff;
  margin: 0 20px;
`;

const LoginButton = styled(WebsiteButton)`
    background-color: transparent;
    border: none;
    margin: 0 10px;
    &:hover{
        background-color: transparent;
        border: none;
        color: rgb(215,215,215);
    } 
`;

const scrollToSection = () => {
    scroller.scrollTo("Header", { smooth: true, duration: 1500, offset: 1 });
}

export function Navbar(props) {
    const { useTransparent } = props;
    const isTablet = useMediaQuery({ maxWidth: MediaBreakpoints.tablet });

    return (
        <NavbarContainer
            useTransparent={useTransparent}
            initial="out"
            animate="in"
            exit="out"
            variants={navbarVariants}
            transition={navbarTransition}
        >

            {/* <Logo
                onClick={scrollToSection}
                inline cursor="pointer"
                logoImage={"Logo2"}
                logoStyle={{
                    align: "center",
                    width: "1.75em",
                    height: "1.75em",
                }}
                logoText={{
                    text: "AIM TECH",
                    margin: "0 10px",
                    size: "12px",
                    weight: "700",
                    color: "#fff",
                }}
            /> */}

            <LinksContainer>
                <LoginText>Customer Access</LoginText>
                {!isTablet && <Seperator />}
                <WebsiteButton small>Contact</WebsiteButton>
                {/* <Link to="/login"> */}
                    <LoginButton small>Login</LoginButton>
                {/* </Link> */}
                <Marginer direction="horizontal" margin="8px" />
            </LinksContainer>
        </NavbarContainer>
    );
}

export default Navbar;
