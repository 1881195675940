import React from 'react';
import styled, { css } from 'styled-components';
import Logo_AIM_1 from '../images/Logo-AIMedtech-1.png';
import Logo_AIM_2 from '../images/Logo-AIMedtech-2.png';


const LogoContainer = styled.div`
    /* border: 1px solid goldenrod; */
    z-index: 1;
    display: flex;
    flex-direction: ${({ inline }) => inline ? "row" : "column"};

    ${({ logoStyle }) => logoStyle && css` 
        position: ${logoStyle.position};
        align-items:  ${logoStyle.align};
        justify-content:  ${logoStyle.justify};
        top:  ${logoStyle.top};
        left:  ${logoStyle.left};
        transform:  ${logoStyle.transform};
    `}
    cursor: ${({ cursor }) => cursor};
`;


const LogoImg = styled.img`
     ${({ logoStyle }) => logoStyle && css` 
        width: ${logoStyle.width};
        height: ${logoStyle.height};
    `}
    max-width: 100px;
    max-height: 100px;

`;


const LogoText = styled.div`
    text-align: center;
    font-family: "Lato";
    letter-spacing: 0.2em;

    ${({ logoText }) => logoText && css` 
        margin:  ${logoText.margin};
        font-size:  ${logoText.size};
        font-weight: ${logoText.weight};
        color: ${logoText.color};
    `}

    ${({ logoText }) => logoText && css` 
        &:before { 
            content: "${logoText.text}" ;
        }
    `};
`;


let logo = null;
function SetLogo(logoImage) {
    if (logoImage === "Logo1") {
        logo = Logo_AIM_1;
    }
    else if (logoImage === "Logo2") {
        logo = Logo_AIM_2;
    }
}


export function Logo(props) {
    const { inline, logoText, logoImage, onClick, cursor, logoStyle } = props;

    SetLogo(logoImage);

    return (
        <LogoContainer onClick={onClick} inline={inline} cursor={cursor} logoStyle={logoStyle}>
            <LogoImg width={logoStyle.width} height={logoStyle.height} src={logo} logoStyle={logoStyle} alt="" />
            <LogoText logoText={logoText} inline={inline} />
        </LogoContainer >
    );
};

export default Logo;