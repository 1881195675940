import React from 'react';
import styled from 'styled-components';
import { LineTheme } from '../../common/StyleTheme';

const LineContainer = styled.span`
    min-width: 125px;
    min-height: 3px;
    margin: 1em;
    background-color: ${LineTheme.PrimaryColor};
    display: flex;
    border-radius: 10px;
`;

export function Line() {
    return <LineContainer />
}

export default Line;